@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600);

@import 'node_modules/bootstrap/scss/bootstrap';

@import url(https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.3/css/select2.min.css);

@import url(https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.6.4/css/bootstrap-datepicker.css);

@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

@import 'general';

.navbar {
  display: none;
}

.btn {
  display: none;
}

input.form-control {
  border: none;
  padding: 0;
  margin: 0;
}

table, td, tr, th {
  padding: 0;
  border: none;
}

label {
  display: inline-block !important;
}

.form-group {
  padding: 0;
  margin: 0;
}

.col-sm-8 {
  display: inline-block !important;
}
