$green: #539C02;
$light-green: #84BA48;
$lightGreen: #00A57E;
$midGreen: #276F5A;
$darkGreen: #184437;
$lightBlue: #A5CCE9;
$red: #e03839;
$orange: #f59a53;

.btn {
  color: white !important;
  cursor: pointer !important;
}

html, body {
  overflow-x: hidden;
}

.navbar, .navbar-collapse {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #1BA47D;

  .navbar-nav {
    float: left;
    width: calc(100vw - 200px);

    li {
      a {
        margin-left: 15px;
        display: inline-block;
        border-radius: 25px;
        padding: 10px 20px;
        background: $midGreen;
        color: white;

        &:hover {
          background: $darkGreen;
          color: white;
        }
      }
    }

    .branch {
      margin-left: 13px;
    }

    .search-bar {
      display: block;
      margin-top: 20px;
      flex: 0 0 100%;
      max-width: 100%;
      font-size: 16px;
      font-weight: bold;
      color: black;
      -webkit-text-fill-color: white;
      -webkit-text-stroke-width: 0.25px;
      -webkit-text-stroke-color: black;

      .fa-plus {
        margin-top: 5px;
      }

      .select2 {
        margin-top: 10px;
      }
    }
  }
  .fa-bars {
    font-size: 25px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6;
    font-family: FontAwesome;
  }
}

.add-icon, .add-icon:hover {
  color: white;
}

.navbar-brand {
  margin-right: 1.5rem;
}

@media (min-width: 768px) {
  .login-button {
    margin-right: 17%;
  }
}

.btn-primary {
  background: $midGreen;
  border-color: $midGreen;
  padding: 2px 25px;
  border-radius: 15px;
}

.customers-select2, .dogs-select2, .hosts-select2, .franchise-select2 {
  .select2-selection__rendered {
    -webkit-text-fill-color: $midGreen;
  }
}

.btn-print {
  color: white !important;
  cursor: pointer;
}

.btn-right {
  margin-right: 10px;
}

.btn-left {
  margin-left: 10px;
}

.panel-default > .card-header {
  background-color: $lightBlue !important;
  font-size: 18px;
  font-weight: bold;
}

footer {
  text-align: center;
  font-size: 11px;
}

.form-check-label {
  font-weight: normal;
  margin-bottom: 10px;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 10px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}
.card-block {
  padding: 15px;
}
.card {
  margin-bottom: 20px;
}
.card-header {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card-block h2 {
  font-size: 16px;
  text-transform: uppercase;
}
.hidden {
  display: none;
}

.today-bookings {
  h3 {
    text-align: center;
  }
}

.email-block {
  margin-top: 20px;
}

.select2-selection {
  padding: 2px;
  border-color: rgba(0, 0, 0, 0.14902) !important;
}

.select2-container .select2-selection--single {
  height: auto;
  border-color: rgba(0, 0, 0, 0.14902) !important;
  font-family: sans-serif;
  font-size: 16px;
}

.add-dog, .edit-dog {
  .fileContainer {
    overflow: hidden;
    position: relative;
    background: $midGreen;
    border-radius: 30px;
    padding: 10px;
    color: white;
  }

  .fileContainer:hover {
    background-color: $darkGreen;
  }

  .fileContainer [type=file] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
  }

  .fileContainer {
    float: left;
  }

  .fileContainer [type=file] {
    cursor: pointer;
  }
}

.dog-profile {
  width: 100%;
}

.host-availability {
  h3 {
    text-align: center;
  }
  .add-host-unavailability {
    text-align: center;
  }
}